import React from 'react';
import { Carousel } from 'antd'; 
import { Link, graphql } from 'gatsby'
import Slider from "react-slick";
import partner01 from '@assets/img/partner01.png';

import SiteLayout from '@layouts/site-layout';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay:true,  
    arrows:true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

   const our_partner = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay:true, 
    slidesToShow: 4,
    arrows:true, 
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
         breakpoint: 480,
         settings: {
           slidesToShow: 1, 
           adaptiveHeight:true,
         }
       },
   ]
  };

export default class InfoPage extends React.Component {
    render() {
        let description =  `STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
        e-learning software product that provides conceptual content delivery in a gamified format. Our
        team comes with deep experience and expertise gathered over many years of teaching and
        training students from various socio-economic strata of the country, preparing them for various
        reputed national and international competitive exams, and turning them into the best and
        brightest this country has ever seen.`
        let img = `img/stepapp_logo.png`
        return (
     <SiteLayout title="partner" description={description} image={img}>
        <div className="home_middle_sec top_margin_comn">
            <div className="home_banner">
                <Carousel {...settings} className="hm_carousel">
                {/* <div className="home_slider">
                    <div className="slide_img"><img src="img/Partner_Banner_1.jpg" alt="banner-images" />
                    </div>
                </div>  */}
                <div className="home_slider">
                    <div className="slide_img">
                        {/* <img src="img/partner01.png" alt="banner-images" /> */}
                    {/* <div className="banner_slide" style={{background:"url('img/partner01.png') no-repeat center center /cover",height: "100vh"}}>  */}
                    <div className="banner_slide" style={{background:`url(${partner01}) no-repeat center center /cover`,height: "100vh"}}> 
                    </div> 
                    <div className="banner_text01 banner_text02 partner_banar">
                        <h2>Partner with Us </h2>
                        <p>Join the Mission to create a level playing field in education.</p>
                        <Link className="banner_btn" to="/contact">Contact us Now</Link>
                    </div>
                    </div>
                </div>  
            </Carousel>
            </div> 
        </div>  

        <div className="benifit_of_franchise">
            <div className="container_main"> 
                <div className="benifit_img">
                <img src="img/partner.png" alt="Benifit of franchise" /></div>
            </div>
        </div>

       <div className="business_sec">
         <div className="container">
         <div className="row">
            <div className="col-md-6">
               <div className="busn_text">
                  <h4>Get a Franchise</h4>
                  <div className="busn_box">
                     <h2>Start your own business with Eduisfun</h2>
                     <p>Today's tech savvy generation spends a colossal amount of time playing games. They are unimpressed by conventional learning systems and there is a need to make learning more engaging and relevant. India's #1 Educational Gaming Venture, Eduisfun franchise provides a platform for enhanced gamified learning experience. With an element of fun, children get hooked and master technical concepts effortlessly with Eduisfun. 
                     To get in touch, <Link to="/contact">click here.</Link>
                  </p>
                  </div>
               </div>
            </div> 
            <div className="col-md-6">
               <div className="busn_img"><img src="img/busn.jpg" title="business Image" /></div>
            </div>
         </div>  
         </div>  
      </div>

      {/* <div className="franchise-table">
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
                  <h3>Earning Potential</h3>
                  <h2>What you Get</h2>
						<div className="table-responsive"> 
							<table className="table table-striped"> 
								<thead>
									<tr>
										<th>Medium</th>
										<th>Qty</th>
										<th>Student (per entry)</th>
										<th>Avg. Net Partner Profit Per Student 
										After All Cost & Taxes</th>
										<th>Total Annual Profits</th>
									</tr>
								</thead> 
								<tbody>
									<tr>
										<th scope="row">Tuitions & Hobby Academies </th>
										<td>5</td>
										<td>500</td>
										<td><i className="fa fa-rupee"></i> &#x20B9; 1000 </td>
										<td><i className="fa fa-rupee"></i>&#x20B9; 500000</td>
									</tr>
									<tr>
										<th scope="row">Schools</th>
										<td>2</td>
										<td>2000</td>
										<td> <i className="fa fa-rupee"></i>&#x20B9; 500 </td>
										<td> <i className="fa fa-rupee"></i>&#x20B9; 1000000</td>
									</tr>
									<tr>
										<th scope="row">Societies, Offices & B2C</th>
										<td>4</td>
										<td>300</td>
										<td> <i className="fa fa-rupee"></i>&#x20B9; 3000</td>
										<td> <i className="fa fa-rupee"></i> &#x20B9; 900000</td>
									</tr> 
								</tbody> 
								<tfoot>
								<tr>
									<th>Annual ROI % (assuming avg. 2L Investment)</th>
									<th>380.00%</th>
									<th>Year 1</th>
									<th>Net Annual Income</th>
									<th> <i className="fa fa-rupee"></i>&#x20B9; 2400000</th>
								</tr>
								<tr>
									<th colSpan="3"></th>
									<th>Net Monthly Income</th>
									<th> <i className="fa fa-rupee"></i>&#x20B9; 200000</th>
								</tr>
								</tfoot>
							</table> 
						</div>
					</div>
				</div>
			</div>
		</div> */}

        <div className="get-start">
         <h4 className="commn_heading">How to get started</h4>
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-sm-6">
						<div className="getstart_text">
							<h2>Invest between 
                        <i className="fa fa-rupee"></i> 2 Lakhs to <i className="fa fa-rupee"></i> 5 Lakhs 
                     </h2>
							<p>
								The investment amount varies depending upon the town, city, district, state, and country in which you are present and the franchise license area offered to the partner. There is no requirement of physical infrastructure. Eduisfun takes care of the product, logistics, operations &amp; support for its partners.
							</p>
						</div>
					</div>
					<div className="col-lg-6 col-sm-6">
						<div className="getstart_text">
							<h2>Long Term Vision</h2>
							<p>Partners must join the franchise network by recognising that this is a business in educational domain with sustained returns, provided one has a long-term vision towards the venture. Games can change the way people learn! Be a part of our ever growing story!</p>
						</div>
					</div>
				</div>
			</div>
		</div>

        <div className="member_counter">
			<div className="container">
				<div className="row">
				 <div className="col-lg-6 col-sm-6 counter-brdr">
                    <div className="single_member_counter">
                        <span>
                            <span className="counter">150</span>+</span>
                            <h4>Total Franchise</h4>
                     </div>
               </div>
               <div className="col-lg-6 col-sm-6">
                  <div className="single_member_counter">
                     <span className="counter">67</span> 
                     <h4> Number of Cities</h4>
                  </div>
               </div> 
			</div>
			</div>
        </div> 

        <div className="container our_partner_sec">
             <h4 className="commn_heading">Our Franchisees</h4> 
             <Slider {...our_partner} className="row our_franchise">
                <div>
                     <div className="col-md-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f1.png" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Kuldeep Chadha</span>
                            <h4>Chartered Accountant</h4> 
                        </div>
                     </div>
                </div> 

                <div>
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f2.png" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Haneesh Undramatla</span>
                            <h4> Co-founder (Autronx) </h4> 
                        </div> 
                    </div>
                 </div>

                 <div>
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f3.png" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Karthik SM</span>
                            <h4>Entrepreneur - Education Sector</h4> 
                        </div> 
                    </div>
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f4.png" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Nirdosh Lampsal</span>
                            <h4>Entrepreneur - Education Sector </h4> 
                        </div> 
                    </div>    
                 </div>  

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f2.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Manu Rathod</span>
                            <h4>President (Lilac Techno School)</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f6.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Ravendra Singh</span>
                            <h4>Entrepreneur - Education Sector</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f7.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Ms. Kanchan Chadha</span>
                            <h4>Entrepreneur - Education Sector</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f8.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. N.K. Dutta</span>
                            <h4>Co-founder (Coffee Vending Machines)</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f9.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Ms. Nisha Darji</span>
                            <h4>Home Maker</h4> 
                        </div> 
                    </div>    
                 </div> 

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f10.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Sachin Bodhani</span>
                            <h4>Entrepreneur - Education Sector</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f11.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Ms. Shobha Ravi Marigoudar</span>
                            <h4>Home Tuition</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f12.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Sudhakar Jadhav</span>
                            <h4>Professor</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f13.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Ms. Elsa Thomas</span>
                            <h4>Home Maker</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f14.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Suman Das</span>
                            <h4>Banker</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f15.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Ms. Rashmi Sekhri</span>
                            <h4>Wedding Designer</h4> 
                        </div> 
                    </div>    
                 </div> 
 
                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f16.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Chandan Mandal</span>
                            <h4>Entrepreneur - Education sector</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/f17.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Chittaranjan</span>
                            <h4>Client Profession : Business</h4> 
                        </div> 
                    </div>    
                 </div>

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/raj_deorai.png" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Raj Deo Rai</span>
                            <h4>Businessman</h4> 
                        </div> 
                    </div>    
                 </div> 

                 <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/jigna_1.png" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Ms. Jignasa Rathod</span>
                            <h4>Business Professional</h4> 
                        </div> 
                    </div>   
                    </div>  

                     <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/Sandesh-Karkera.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Sandesh Karkera</span>
                            <h4>Business Professional</h4> 
                        </div> 
                    </div>   
                    </div>   

                    <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/santhosh-vadlakonda.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Santhosh Vadlakonda </span>
                            <h4>Business Professional</h4> 
                        </div> 
                    </div>   
                    </div>  

                    <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/minakhi.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mrs. Minakhi Biswas</span>
                            <h4>Entrepreneur - Education Sector</h4>   
                        </div> 
                    </div>   
                    </div>

                    <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/sanjay.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">
                            <span>Mr. Sanjay N Bhadane</span>
                            <h4>Entrepreneur - Education Sector</h4>  
                        </div> 
                    </div>   
                    </div>

                    <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/rahul.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">  
                            <span>Mr. Rahul Malik</span>
                            <h4>Entrepreneur - Education Sector</h4> 
                        </div> 
                    </div>   
                    </div>

                    <div> 
                    <div className="col-lg-12">
                        <div className="single-patnar text-center mt-20">
                            <img src="img/sanjay-tyagi.jpg" alt="Logo" />
                        </div>
                        <div className="team_text">  
                            <span> Sanjay Tyagi </span>
                            <h4>Business Professional</h4> 
                        </div> 
                    </div>   
                    </div>
               

              </Slider>  
         </div> 
            </SiteLayout>
        )
    }
}
